function useCDNStorage(file: File) {
  const { data, execute } = useFetch<{ url: string }>('/api/upload', {
    method: 'POST',
    headers: {
      'content-type': file?.type || 'application/octet-stream',
      'x-vercel-filename': file?.name || 'image.png',
    },
    body: file,
    immediate: false,
  })

  return {
    data,
    execute,
  }
}

export default useCDNStorage
